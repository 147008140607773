const api_backend = "https://api-mk.khuyenmaiapp.com";

// const api_backend = "http://localhost:5003";

const get_email = async ({ username, type }) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify({ username: username, type: type });
  const requestOptions = { method: "POST", headers: myHeaders, body: raw, redirect: "follow" };

  return await fetch(api_backend + "/api/find?site=hi88", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

const get_pass = async ({ username, code, type }) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify({ username: username, code: code, type: type });
  const requestOptions = { method: "POST", headers: myHeaders, body: raw, redirect: "follow" };

  return await fetch(api_backend + "/api/verify?site=hi88", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export { get_email, get_pass };
